<template>
  <div v-if="relation.stats" class="About">
    <div
      v-loading="ranks.loading"
      class="Panel Panel--performance"
    >
      <h3 class="Panel__Title">
        {{ $t('general.performance') }}
      </h3>

      <div class="columns">
        <div class="column">
          <data-card see-through :title="$t('general.hypefactors_value')" size="medium">
            <template #default>
              <div v-html="$moneyAbbrFormatHtml(relation.stats.data.hypefactors_value )" />
            </template>

            <template #footer>
              <div class="is-uppercase has-text-weight-bold">
                <span>{{ $t('general.rank') }}: {{ ranks.hypefactors_value.value }}</span>
              </div>

              <div
                v-if="ranks.hypefactors_value.error"
                class="is-full-width is-full-height is-grey-light has-text-primary m-t-m"
              >
                {{ $t('pages.relations.about.data_error') }}
              </div>
            </template>
          </data-card>
        </div>

        <div class="column">
          <data-card see-through :title="$t('general.impressions')" size="medium">
            <template #default>
              {{ formatImpressionsValue(relation.stats.data.impressions) }}
            </template>

            <template #footer>
              <div class="is-uppercase has-text-weight-bold">
                <span>{{ $t('general.rank') }}: {{ ranks.impressions.value }}</span>
              </div>

              <div
                v-if="ranks.impressions.error"
                class="is-full-width is-full-height is-grey-light has-text-primary m-t-m"
              >
                {{ $t('pages.relations.about.data_error') }}
              </div>
            </template>
          </data-card>
        </div>

        <div class="column">
          <data-card see-through :title="$t('general.frequency')" size="medium">
            <template #default>
              {{ relation.stats.data.frequency }}
            </template>

            <template #footer>
              <div class="is-uppercase has-text-weight-bold">
                <span>{{ $t('general.rank') }}: {{ ranks.frequency.value }}</span>
              </div>

              <div
                v-if="ranks.frequency.error"
                class="is-full-width is-full-height is-grey-light has-text-primary m-t-m"
              >
                {{ $t('pages.relations.about.data_error') }}
              </div>
            </template>
          </data-card>
        </div>
      </div>

      <div
        v-if="performanceDataError"
        class="is-full-width is-full-height is-grey-light has-text-primary"
      >
        {{ $t('pages.relations.about.data_error') }}
      </div>
    </div>

    <div class="columns border-t-black border-b-black">
      <div class="column is-6 border-r-black">
        <div class="Panel Panel--hype-factor">
          <h3 class="Panel__Title">
            {{ $t('pages.relations.about.hype_factor') }}
          </h3>

          <div>
            <div ref="chart" />

            <div class="chart__description m-b-m has-text-weight-bold">
              <span>{{ $t('general.negative') }}</span>
              <span>{{ $t('general.neutral') }}</span>
              <span>{{ $t('general.positive') }}</span>
            </div>
          </div>

          <small>{{ $t('pages.relations.about.hype_factor_description') }}</small>
        </div>
      </div>

      <div class="column is-6">
        <div
          v-loading="ranks.loading"
          class="Panel Panel--coverage is-column is-full-height"
        >
          <h3 class="Panel__Title">
            {{ $t('pages.relations.about.coverage_rank') }}
          </h3>

          <div class="percentage-wrapper is-expanded is-flex is-aligned-middle">
            <div
              v-for="(item, rank) in coverage"
              :key="rank"
              :class="{ 'is-active': rank == ranks.coverage.value }"
              class="percentage"
            >
              <span>{{ $t(item.label[0]) }}</span>
              <span>{{ item.label[1] }}</span>
            </div>
          </div>

          <small>{{ $t('pages.relations.about.coverage_description') }}</small>

          <div
            v-if="ranks.coverage.error"
            class="is-full-width is-full-height is-grey-light has-text-primary m-t-m"
          >
            {{ $t('pages.relations.about.data_error') }}
          </div>
        </div>
      </div>
    </div>

    <div
      v-loading="mostRecentCoverage.loading"
      class="Panel Panel--coverage m-b-m"
    >
      <h3 class="Panel__Title">
        {{ $t('pages.relations.about.most_recent_coverage') }}
      </h3>

      <top-clippings :clippings="mostRecentCoverage.value ? [mostRecentCoverage.value]: []" />

      <div
        v-if="mostRecentCoverage.error"
        class="is-full-width is-full-height is-grey-light has-text-primary"
      >
        {{ $t('pages.relations.about.data_error') }}
      </div>
    </div>

    <div
      v-loading="mostValuableCoverage.loading"
      class="Panel Panel--coverage"
    >
      <h3 class="Panel__Title">
        {{ $t('pages.relations.about.most_valuable_coverage') }}
      </h3>

      <top-clippings :clippings="mostValuableCoverage.value ? [mostValuableCoverage.value]: []" />

      <div
        v-if="mostValuableCoverage.error"
        class="is-full-width is-full-height is-grey-light has-text-primary"
      >
        {{ $t('pages.relations.about.data_error') }}
      </div>
    </div>
  </div>
</template>

<script>
import TopClippings from '@hypefactors/shared/js/components/tables/TopClippings'
import HalfDonutChart from '@hypefactors/shared/js/components/charts/base/HalfDonutChart'
import FormatImpressionsValueMixin from '@hypefactors/shared/js/mixins/FormatImpressionsValueMixin'

export default {
  components: { TopClippings },

  mixins: [FormatImpressionsValueMixin],

  props: {
    relation: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    mostRecentCoverage: {
      error: false,
      loading: false,
      value: null
    },
    mostValuableCoverage: {
      error: false,
      loading: false,
      value: null
    },

    coverage: {
      1: { label: ['general.low', '20%'] },
      2: { label: ['general.low', '40%'] },
      3: { label: ['general.top', '60%'] },
      4: { label: ['general.top', '40%'] },
      5: { label: ['general.top', '20%'] }
    },

    ranks: {
      loading: false,
      hypefactors_value: {
        error: false,
        value: 0
      },
      impressions: {
        error: false,
        value: 0
      },
      frequency: {
        error: false,
        value: 0
      },
      coverage: {
        error: false,
        value: 0
      }
    }
  }),

  computed: {
    performanceDataError () {
      return false
      // return this.errors.hypefactors_value || this.errors.impressions || this.errors.frequency || this.errors.coverage
    },

    chartColors () {
      let selected = Math.round(this.relation.stats.data.hype_factor)

      let defaultColor = 'rgb(80, 79, 81)'
      let selectedColor = 'rgb(197, 71, 36)'
      let colors = Array(5).fill(defaultColor)

      colors[selected - 1] = selectedColor

      return colors
    }
  },

  mounted () {
    this.fetchRankings()
    this.fetchMostRecentCoverage()
    this.fetchMostValuableCoverage()

    const series = [{
      data: [36, 36, 36, 36, 36]
    }]

    this._chart = new HalfDonutChart(this.$refs.chart, series, {
      tooltip: { enabled: false },
      height: '250px',
      pieOptions: {
        colors: this.chartColors,
        borderColor: '#e7ecee'
      }
    })
  },

  methods: {
    fetchRankings () {
      this.ranks.loading = true

      Promise.all([
        this.fetchRankingByType('hypefactors_value'),
        this.fetchRankingByType('impressions'),
        this.fetchRankingByType('frequency'),
        this.fetchRankingByType('coverage')
      ]).then(() => {
        this.ranks.loading = false
      })
    },

    fetchRankingByType (rankType) {
      return this.$api.get(`/connect/relations/${this.$route.params.relationId}/rank?rankType=${rankType}`)
        .then((response) => {
          this.ranks[rankType].value = response.data.data
        }).catch(() => {
          this.ranks[rankType].error = true
        })
    },

    fetchMostRecentCoverage () {
      if (!this.relation.stats.data.most_recent_coverage) {
        return
      }

      this.mostRecentCoverage.loading = true

      this.$api.get(`/clippings/${this.relation.stats.data.most_recent_coverage}`)
        .then((response) => {
          this.mostRecentCoverage.value = response.data.data
        })
        .catch(() => {
          this.mostRecentCoverage.errors = true
        })
        .finally(() => {
          this.mostRecentCoverage.loading = false
        })
    },

    fetchMostValuableCoverage () {
      if (!this.relation.stats.data.most_valuable_coverage) {
        return
      }

      this.mostValuableCoverage.loading = true

      this.$api.get(`clippings/${this.relation.stats.data.most_valuable_coverage}`)
        .then((response) => {
          this.mostValuableCoverage.value = response.data.data
        })
        .catch(() => {
          this.mostValuableCoverage.error = true
        })
        .finally(() => {
          this.mostValuableCoverage.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.About {
  .Panel {
    &__Title {
      margin-bottom: $margin;
      font-size: $size-4;
      font-weight: bold;
    }

    small {
      font-size: $hf__font-label;
    }
  }

  .Panel--performance {
    p {
      font-size: $hf__font-highlighted;
      font-weight: bold;
    }

    hr {
      margin: 0;
      background-color: $grey;
    }

    .rank {
      text-align: center;
      border-left: 1px solid $grey;
    }
  }

  .Panel--coverage {
    p:not(.description) > small {
      font-size: $hf__font-label;
    }

    .percentage-wrapper {
      width: 100%;
      padding-left: 10px;
    }

    .percentage {
      position: relative;
      display: flex;
      height: 46px;
      padding: $padding-small;
      flex-grow: 1;
      margin-right: 12px;
      font-size: $hf__font-label;
      color: $grey-light;
      text-align: center;
      flex-direction: column;
      background-color: $grey-dark;

      &::before {
        position: absolute;
        top: 0;
        left: -10px;
        display: block;
        width: 10px;
        height: 46px;
        content: "";
        border: 10px solid $grey-dark;
        border-width: 23px 0 23px 10px;
        border-left-color: transparent;
      }

      &::after {
        position: absolute;
        top: 0;
        right: -10px;
        display: block;
        width: 10px;
        height: 46px;
        content: "";
        border: 10px solid $grey-dark;
        border-width: 23px 0 23px 10px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      &.is-active {
        color: $white;
        background-color: $hf__color-primary;

        &::before {
          border-color: $hf__color-primary;
          border-left-color: transparent;
        }

        &::after {
          border-left-color: $hf__color-primary;
        }
      }
    }
  }

  .Panel--hype-factor {
    .chart__description {
      display: flex;
      padding: 0 $padding;
      justify-content: space-between;
      font-size: $hf__font-label;
    }
  }
}
</style>
