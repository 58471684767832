import Chart from './Chart'

class HalfDonut extends Chart {
  constructor (ref, series, options) {
    super(ref, series)
    this.setOptions(this.donutChartOptions(options))

    this.init()
  }

  donutChartOptions ({ title, tooltip, subtitle, formatter, height = null, events, colors, pieOptions }) {
    tooltip = tooltip || {
      valueSuffix: '%' // custom formatter overrides this
    }

    if (formatter) {
      tooltip.formatter = formatter
    }

    return {
      chart: {
        backgroundColor: 'transparent',
        type: 'pie',
        height,
        events
      },
      title: {
        text: title,
        verticalAlign: 'bottom'
      },
      subtitle: {
        text: subtitle,
        verticalAlign: 'bottom',
        y: 10
      },
      tooltip,
      colors,
      plotOptions: {
        pie: {
          shadow: false,
          startAngle: -90,
          endAngle: 90,
          center: ['50%', '100%'],
          size: '130%',
          borderWidth: 4,
          innerSize: '75%',
          dataLabels: {
            enabled: false
          },
          ...pieOptions
        }
      }
    }
  }
}

export default HalfDonut
